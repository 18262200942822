/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Injectable } from '@angular/core';
import { User } from '../../auth/user';

@Injectable()
export class NoopMessengerClientService {
  initialize(): void {}
  reset(): void {}
  shutdown(): void {}
  updatePosition(): void {}
  updateUser(user: User): void {}
}
