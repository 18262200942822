import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
  computed,
  effect,
  inject,
  input,
} from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { MatTooltip } from '@angular/material/tooltip';
import { ReplaySubject, filter, of, share, switchMap, take } from 'rxjs';
import { createLawArticleTitle } from '../law-article-helper';
import { LawArticlePreviewDialogComponent } from '../law-article-preview-dialog/law-article-preview-dialog.component';
import { LawArticleService } from '../law-article.service';

@Component({
  selector: 'jrp-law-article-reference',
  imports: [],
  templateUrl: './law-article-reference.component.html',
  styleUrl: './law-article-reference.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  hostDirectives: [MatTooltip],
  host: {
    role: 'button',
    '[attr.tabindex]': 'disabled() ? -1 : 0',
    '[attr.disabled]': 'disabled() || null',
    '[attr.aria-disabled]': 'disabled() || null',
    'aria-haspopup': 'dialog',
    class: 'jrp-law-article-reference',
    '(click)': 'openPreview()',
    '(keydown.enter)': 'openPreview()',
  },
})
export class LawArticleReferenceComponent {
  private readonly lawArticleDataClient = inject(LawArticleService);
  private readonly dialog = inject(MatDialog);
  private readonly tooltip = inject(MatTooltip, { host: true });

  readonly lawArticleId = input<string | null>(null, { alias: 'dataId' });
  protected readonly lawArticleNumber = input<string>(undefined, {
    alias: 'dataNumber',
  });
  protected readonly lawArticleName = input.required<string>({
    alias: 'dataName',
  });

  private lawArticle = toObservable(this.lawArticleId).pipe(
    switchMap((id) => (id ? this.lawArticleDataClient.find(id) : of(null))),
    share({
      connector: () => new ReplaySubject(1),
      resetOnRefCountZero: false,
      resetOnComplete: false,
    }),
  );

  readonly title = computed(() => {
    const number = this.lawArticleNumber();
    const name = this.lawArticleName();

    return createLawArticleTitle(name, number);
  });

  readonly disabled = computed(() => !this.lawArticleId());

  constructor() {
    effect(() => {
      this.tooltip.disabled = this.disabled();
    });
    effect(() => {
      this.tooltip.message = this.title();
    });
  }

  openPreview(): void {
    this.lawArticle.pipe(take(1), filter(Boolean)).subscribe((lawArticle) => {
      this.dialog.open(LawArticlePreviewDialogComponent, {
        data: lawArticle,
        width: '960px',
        maxWidth: '100%',
        panelClass: 'jrp-full-screen-dialog',
      });
    });
  }
}
