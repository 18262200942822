import { effect, inject, Injectable, InjectionToken } from '@angular/core';
import { EventType, Router } from '@angular/router';
import { DateTime } from 'luxon';
import { filter } from 'rxjs';
import { AuthService } from '../../auth/auth.service';
import { User } from '../../auth/user';
import { INTERCOM_MESSENGER } from './intercom-messenger';
import {
  MessengerClientService,
  MessengerPositionParams,
} from './messenger-client';

export interface IntercomMessengerConfig {
  appId: string;
  region: 'us' | 'eu' | 'ap';
}

export const INTERCOM_MESSENGER_CONFIG =
  new InjectionToken<IntercomMessengerConfig>(
    'Intercom Messenger configuration',
  );

@Injectable()
export class IntercomMessengerClientService extends MessengerClientService {
  private readonly auth = inject(AuthService);
  private readonly router = inject(Router);
  private readonly messenger = inject(INTERCOM_MESSENGER);
  private readonly config = inject(INTERCOM_MESSENGER_CONFIG);

  private readonly navigationEnd = this.router.events.pipe(
    filter((event) => event.type === EventType.NavigationEnd),
  );

  constructor() {
    super();
    this.initialize();

    this.navigationEnd.subscribe(() => {
      this.messenger.update({});
    });

    effect((onCleanup) => {
      const user = this.auth.user();

      if (user) {
        this.updateUser(user);

        onCleanup(() => {
          this.shutdown();
          this.reset();
        });
      }
    });
  }

  reset(): void {
    this.messenger.boot({ app_id: this.config.appId });
  }

  shutdown(): void {
    this.messenger.shutdown();
  }

  initialize() {
    this.messenger.Intercom({
      app_id: this.config.appId,
      region: this.config.region,
    });
  }

  updatePosition(params: MessengerPositionParams): void {
    this.messenger.update({
      z_index: params?.zIndex,
      horizontal_padding: params?.padding?.inline,
      vertical_padding: params?.padding?.block,
    });
  }

  updateUser(user: User): void {
    const [lastname, firstname] = user.fullname.split(' ');

    this.messenger.update({
      name: `${lastname.toUpperCase()} ${firstname}`,
      email: user.email,
      created_at: DateTime.fromISO(user.createdAt).toUnixInteger(),
      user_hash: user.hash,
      occupation: user.occupation,
      clients: user.clients.map((client) => client.name),
    });
  }
}
