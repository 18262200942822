import { Injectable, effect, inject } from '@angular/core';
import { MatomoTracker } from 'ngx-matomo-client';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../auth/auth.service';
import { User } from '../../../auth/user';
import { TrackingCustomDimension } from './tracking-custom-dimension';

@Injectable({
  providedIn: 'root',
})
export class MatomoService {
  private readonly auth = inject(AuthService);
  private readonly matomo = inject(MatomoTracker);

  constructor() {
    if (!environment.matomo.disabled) {
      effect(() => {
        const user = this.auth.user();

        if (user) {
          this.identify(user);
        } else {
          this.reset();
        }
      });
    }
  }

  identify(user: User): void {
    this.matomo.setUserId(`JP${user.id}`);
    this.matomo.setCustomDimension(
      TrackingCustomDimension.UserType,
      user.lawyerInfos ? 'Lawyer' : 'Non-lawyer',
    );
    this.matomo.setCustomDimension(
      TrackingCustomDimension.Category,
      user.category,
    );
  }

  reset(): void {
    this.matomo.resetUserId();
    this.matomo.deleteCustomDimension(TrackingCustomDimension.UserType);
    this.matomo.deleteCustomDimension(TrackingCustomDimension.Category);
  }
}
