import { DOCUMENT } from '@angular/common';
import { Injectable, effect, inject } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../auth/auth.service';
import { User } from '../../../auth/user';
import { WINDOW } from '../../window';
import {
  UserGuidingClient,
  UserGuidingLayerEntry,
  UserGuidingTemporaryClient,
} from './user-guiding';

export type WindowWithUserGuiding = Window &
  typeof globalThis & {
    userGuiding?: UserGuidingClient;
    userGuidingLayer?: UserGuidingLayerEntry[];
  };

@Injectable({
  providedIn: 'root',
})
export class UserGuidingService {
  private readonly window = inject<WindowWithUserGuiding>(WINDOW);
  private readonly document = inject(DOCUMENT);
  private readonly auth = inject(AuthService);

  constructor() {
    if (environment.userGuiding.containerId) {
      this.initDataLayer();
      this.install(environment.userGuiding.containerId);
      this.initTemporaryClient();

      effect(() => {
        const user = this.auth.user();
        if (user) {
          this.identify(user);
        }
      });
    }
  }

  identify(user: User): void {
    this.window.userGuiding?.identify(user.id.toString(), {
      fullname: user.fullname,
      email: user.email,
    });
  }

  private install(containerId: string): void {
    if (!this.document.querySelector('#user-guiding-script')) {
      const script = this.document.createElement('script');
      script.async = true;
      script.src = `https://static.userguiding.com/media/user-guiding-${containerId}-embedded.js`;
      script.id = 'user-guiding-script';
      this.document.head.appendChild(script);
    }
  }

  private initDataLayer(): void {
    if (!this.window.userGuidingLayer) {
      this.window.userGuidingLayer = [];
    }
  }

  private initTemporaryClient(): void {
    if (!this.window.userGuiding) {
      const temporaryClient: UserGuidingTemporaryClient = {
        q: [],
        identify: (...args) => {
          temporaryClient.q.push(['identify', args]);
        },
      };
      this.window.userGuiding = temporaryClient;
    }
  }
}
