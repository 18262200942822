import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatAnchor, MatButton } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle,
} from '@angular/material/dialog';
import { RouterLink } from '@angular/router';

export interface DetectCourtDecisionPreviewData {
  id: string;
  shortTitle: string;
  abstract: string;
  operative: string;
}

@Component({
  selector: 'jrp-detect-court-decision-preview-dialog',
  templateUrl: './detect-court-decision-preview-dialog.component.html',
  styleUrl: './detect-court-decision-preview-dialog.component.scss',
  imports: [
    MatButton,
    MatAnchor,
    RouterLink,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetectCourtDecisionPreviewDialogComponent {
  protected readonly data =
    inject<DetectCourtDecisionPreviewData>(MAT_DIALOG_DATA);
}
