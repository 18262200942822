import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
  computed,
  effect,
  inject,
  numberAttribute,
  signal,
  untracked,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTooltip } from '@angular/material/tooltip';
import { DetectCourtDecisionPreviewDialogComponent } from '../detect-court-decision-preview-dialog/detect-court-decision-preview-dialog.component';
import { DetectLawArticlePreviewDialogComponent } from '../detect-law-article-preview-dialog/detect-law-article-preview-dialog.component';

@Component({
  selector: 'jrp-detect-reference',
  templateUrl: './detect-reference.component.html',
  styleUrl: './detect-reference.component.scss',
  standalone: true,
  imports: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  hostDirectives: [MatTooltip],
  host: {
    '[id]': 'id()',
    role: 'button',
    '[attr.tabindex]': 'available() ? 0 : -1',
    '[attr.disabled]': '!available() || null',
    '[attr.aria-disabled]': '!available() || null',
    'aria-haspopup': 'dialog',
    class: 'jrp-detect-reference',
    '[class.available]': 'available()',
    '[class.unavailable]': '!available()',
    '(click)': 'openPreview()',
    '(keydown.enter)': 'openPreview()',
  },
})
export class DetectReferenceComponent {
  private readonly dialog = inject(MatDialog);
  private readonly tooltip = inject(MatTooltip, { host: true });

  protected readonly available = signal(false);

  @Input()
  set dataIdentifier(id: string) {
    untracked(() => {
      this.identifier.set(id);
      this.available.set(true);
    });
  }

  @Input()
  set dataUnknownRefId(id: string) {
    untracked(() => {
      this.identifier.set(id);
      this.available.set(false);
    });
  }

  protected readonly identifier = signal('');

  @Input({ transform: numberAttribute })
  set dataOffset(offset: number) {
    untracked(() => this.offset.set(offset));
  }
  protected readonly offset = signal<number>(0);

  protected id = computed(
    () =>
      `jrp-detect-reference-${
        this.available()
          ? `${this.identifier()}-${this.offset()}`
          : this.identifier()
      }`,
  );

  readonly isLawArticleReference = computed(() => {
    const identifier = this.identifier();
    return Boolean(identifier.startsWith('LEGIART'));
  });

  @Input()
  set dataAbstract(abstract: string | undefined) {
    untracked(() => this.abstract.set(abstract));
  }
  protected readonly abstract = signal<string | undefined>(undefined);

  @Input()
  set dataMetadataStartDate(startDate: string | undefined) {
    untracked(() => this.startDate.set(startDate));
  }
  protected readonly startDate = signal<string | undefined>(undefined);

  @Input()
  set dataMetadataStatus(status: string) {
    untracked(() => this.status.set(status));
  }
  protected readonly status = signal<string>('ACTIVE');

  @Input()
  set dataShortTitle(shortTitle: string | undefined) {
    untracked(() => this.shortTitle.set(shortTitle));
  }
  protected readonly shortTitle = signal<string | undefined>(undefined);

  @Input()
  set dataMetadataSolution(solution: string | undefined) {
    untracked(() => this.solution.set(solution));
  }
  protected readonly solution = signal<string | undefined>(undefined);

  constructor() {
    effect(() => {
      this.tooltip.disabled = !this.available();
    });
    effect(() => {
      this.tooltip.message = `${this.isLawArticleReference() ? "Voir l'article de loi" : 'Voir la décision'} ${this.identifier()}`;
    });
  }

  protected openPreview(): void {
    if (this.available()) {
      if (this.isLawArticleReference()) {
        this.openLawArticlePreview();
      } else {
        this.openCourtDecisionPreview();
      }
    }
  }

  private openLawArticlePreview(): void {
    this.dialog.open(DetectLawArticlePreviewDialogComponent, {
      data: {
        id: this.identifier(),
        shortTitle: this.shortTitle(),
        abstract: this.abstract(),
        startDate: this.startDate(),
        status: this.status(),
      },
      width: '640px',
    });
  }

  private openCourtDecisionPreview(): void {
    this.dialog.open(DetectCourtDecisionPreviewDialogComponent, {
      data: {
        id: this.identifier(),
        shortTitle: this.shortTitle(),
        abstract: this.abstract(),
        operative: this.solution(),
      },
      width: '640px',
    });
  }
}
