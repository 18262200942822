import { Injectable, inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';

export const TITLE_SUFFIX = "Juri'Predis";
export const TITLE_SEPARATOR = ' | ';

@Injectable({ providedIn: 'root' })
export class JuripredisTitleStrategy extends TitleStrategy {
  private readonly title = inject(Title);

  override updateTitle(routerState: RouterStateSnapshot) {
    const title = this.buildTitle(routerState);
    this.title.setTitle(
      title ? `${title}${TITLE_SEPARATOR}${TITLE_SUFFIX}` : TITLE_SUFFIX,
    );
  }
}
