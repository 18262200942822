<h2 mat-dialog-title>{{ data.shortTitle }}</h2>
<mat-dialog-content>
  @if (data.operative) {
    <p class="operative">
      {{ data.operative }}
    </p>
  }
  <p class="abstract">{{ data.abstract }}</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Fermer</button>
  <a
    mat-flat-button
    mat-dialog-close
    cdkFocusInitial
    [routerLink]="['/decisions', data.id]"
    target="_blank"
    >Voir la décision</a
  >
</mat-dialog-actions>
