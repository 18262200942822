import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../auth.service';

export const signInWithTokenGuard: CanActivateFn = (route) => {
  const auth = inject(AuthService);
  const router = inject(Router);

  const token = route.queryParamMap.get('token');
  if (token) {
    auth.signInWithToken(token);
    return router.parseUrl('/');
  }
  return true;
};
