import { inject } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { CanActivateFn, Router } from '@angular/router';
import { map, skipWhile, take } from 'rxjs';
import { AuthService } from '../auth.service';

export const userVerificationGuard: CanActivateFn = () => {
  const router = inject(Router);
  const auth = inject(AuthService);
  return toObservable(auth.user).pipe(
    skipWhile((user) => user === undefined),
    take(1),
    map((user) => !user || user.verified || router.parseUrl('/verification')),
  );
};
