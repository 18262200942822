import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { isApiGatewayRequest, isApiRequest } from '../../core/api/api-helper';
import { AuthTokenService } from '../auth-token/auth-token.service';

export const legacyTokenAuthInterceptor: HttpInterceptorFn = (req, next) => {
  const token = inject(AuthTokenService).value();
  if (token && (isApiRequest(req) || isApiGatewayRequest(req))) {
    const authenticatedRequest = req.clone({
      setHeaders: {
        'X-Authorization': token,
      },
    });
    return next(authenticatedRequest);
  }
  return next(req);
};
