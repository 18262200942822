import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  provideHttpClient,
  withFetch,
  withInterceptors,
} from '@angular/common/http';
import {
  APP_INITIALIZER,
  ApplicationConfig,
  ENVIRONMENT_INITIALIZER,
  Injector,
  LOCALE_ID,
  inject,
  isDevMode,
  provideZoneChangeDetection,
} from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { provideDateFnsAdapter } from '@angular/material-date-fns-adapter';
import { MAT_CARD_CONFIG } from '@angular/material/card';
import { MAT_CHIPS_DEFAULT_OPTIONS } from '@angular/material/chips';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import {
  MAT_ICON_DEFAULT_OPTIONS,
  MatIconRegistry,
} from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {
  TitleStrategy,
  provideRouter,
  withComponentInputBinding,
  withInMemoryScrolling,
} from '@angular/router';
import { provideServiceWorker } from '@angular/service-worker';
import { fr } from 'date-fns/locale';
import { provideMatomo, withRouter } from 'ngx-matomo-client';
import { environment } from '../environments/environment';
import {
  GDRP_ACCEPTANCE_CHECKBOX_LABEL,
  gdrpAcceptanceCheckboxLabelFactory,
} from './account/gdpr-acceptance-checkbox-label';
import { routes } from './app.routes';
import { legacyTokenAuthInterceptor } from './auth/legacy/legacy-token-auth.interceptor';
import { apiErrorInterceptor } from './core/api/api-error.interceptor';
import { STORAGE, localStorageFactory } from './core/storage/storage';
import { JuripredisTitleStrategy } from './core/title-strategies/juripredis-title-strategy.service';
import { MatomoService } from './core/tracking/matomo/matomo.service';
import { UserGuidingService } from './core/tracking/user-guiding/user-guiding.service';
import { WINDOW } from './core/window';
import { DetectReferenceComponent } from './detect/detect-references/detect-reference/detect-reference.component';
import { LawArticleReferenceComponent } from './law-article/law-article-reference/law-article-reference.component';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(
      routes,
      withInMemoryScrolling({
        anchorScrolling: 'enabled',
        scrollPositionRestoration: 'enabled',
      }),
      withComponentInputBinding(),
    ),
    provideHttpClient(
      withFetch(),
      withInterceptors([apiErrorInterceptor, legacyTokenAuthInterceptor]),
    ),
    provideAnimationsAsync(),
    provideMatomo(
      {
        trackerUrl: environment.matomo.trackerUrl,
        siteId: environment.matomo.siteId,
        disabled: environment.matomo.disabled,
        runOutsideAngularZone: true,
      },
      withRouter(),
    ),
    provideDateFnsAdapter({
      parse: {
        dateInput: ['P', 'dd-MM-yyyy', 'ddMMyyyy'],
      },
      display: {
        dateInput: 'P',
        monthYearLabel: 'LLL uuuu',
        dateA11yLabel: 'PP',
        monthYearA11yLabel: 'LLLL uuuu',
      },
    }),
    {
      provide: MAT_DATE_LOCALE,
      useValue: fr,
    },
    {
      provide: STORAGE,
      useFactory: localStorageFactory,
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' },
    },
    {
      provide: MAT_CARD_CONFIG,
      useValue: { appearance: 'outlined' },
    },
    {
      provide: MAT_ICON_DEFAULT_OPTIONS,
      useValue: { fontSet: 'material-symbols-outlined' },
    },
    {
      provide: MAT_CHIPS_DEFAULT_OPTIONS,
      useValue: { separatorKeyCodes: [ENTER, COMMA] },
    },
    { provide: LOCALE_ID, useValue: 'fr' },
    {
      provide: GDRP_ACCEPTANCE_CHECKBOX_LABEL,
      useFactory: gdrpAcceptanceCheckboxLabelFactory,
    },
    { provide: TitleStrategy, useClass: JuripredisTitleStrategy },
    {
      provide: ENVIRONMENT_INITIALIZER,
      useValue: () => inject(MatomoService),
      multi: true,
    },
    {
      provide: ENVIRONMENT_INITIALIZER,
      useValue: () => inject(UserGuidingService),
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => {
        const iconRegistry = inject(MatIconRegistry);
        const sanitizer = inject(DomSanitizer);

        return () => {
          iconRegistry.addSvgIconResolver((name, namespace) => {
            const iconPath = `icons/${namespace ? `${namespace}/` : ''}${name}.svg`;
            return sanitizer.bypassSecurityTrustResourceUrl(iconPath);
          });
        };
      },
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => {
        const customElementRegistry = inject(WINDOW).customElements;
        const injector = inject(Injector);

        return () => {
          customElementRegistry.define(
            'data-reference',
            createCustomElement(DetectReferenceComponent, {
              injector,
            }),
          );
          customElementRegistry.define(
            'data-article',
            createCustomElement(LawArticleReferenceComponent, {
              injector,
            }),
          );
        };
      },
      multi: true,
    },
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
};
