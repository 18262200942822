<h2 mat-dialog-title>{{ lawArticle.title }}</h2>
<header class="law-article-infos">
  <jrp-law-article-status [status]="lawArticle.status"></jrp-law-article-status>
  <span class="law-article-period">
    @switch (!lawArticle.date.end) {
      @case (true) {
        Depuis le
        <time [attr.datetime]="lawArticle.date.start">{{
          lawArticle.date.start | date: "longDate"
        }}</time>
      }
      @default {
        Du
        <time [attr.datetime]="lawArticle.date.start">{{
          lawArticle.date.start | date: "longDate"
        }}</time>
        au
        <time [attr.datetime]="lawArticle.date.end">{{
          lawArticle.date.end | date: "longDate"
        }}</time>
      }
    }
  </span>
</header>
<mat-dialog-content
  class="law-article-content"
  [innerHtml]="lawArticle.content | bypassSecurityTrustHtml"
></mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Fermer</button>
  <a
    mat-flat-button
    mat-dialog-close
    cdkFocusInitial
    [routerLink]="['/codes-et-lois', lawArticle.id]"
    target="_blank"
    >Voir l'article</a
  >
</mat-dialog-actions>
