import { HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ApiError } from './api-error';

export function isApiRequest(request: HttpRequest<unknown>): boolean {
  return request.url.startsWith(environment.apiUrl);
}

export function isApiGatewayRequest(request: HttpRequest<unknown>): boolean {
  return request.url.startsWith(environment.apiGatewayUrl);
}

export function isApiErrorResponse(
  error: HttpErrorResponse['error'],
): error is ApiError {
  return Boolean(error && error.code && error.description);
}
