import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatAnchor, MatButton } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle,
} from '@angular/material/dialog';
import { RouterLink } from '@angular/router';

export interface DetectLawArticlePreviewData {
  id: string;
  shortTitle: string;
  abstract: string;
  startDate: string;
  status: 'ACTIVE' | 'DEPRECATED';
}

@Component({
  selector: 'jrp-detect-law-article-preview-dialog',
  templateUrl: './detect-law-article-preview-dialog.component.html',
  styleUrl: './detect-law-article-preview-dialog.component.scss',
  standalone: true,
  imports: [
    MatButton,
    MatAnchor,
    RouterLink,
    DatePipe,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetectLawArticlePreviewDialogComponent {
  protected readonly data =
    inject<DetectLawArticlePreviewData>(MAT_DIALOG_DATA);
}
