import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
  computed,
  effect,
  inject,
  input,
  numberAttribute,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTooltip } from '@angular/material/tooltip';
import { DetectCourtDecisionPreviewDialogComponent } from '../detect-court-decision-preview-dialog/detect-court-decision-preview-dialog.component';
import { DetectLawArticlePreviewDialogComponent } from '../detect-law-article-preview-dialog/detect-law-article-preview-dialog.component';

@Component({
  selector: 'jrp-detect-reference',
  templateUrl: './detect-reference.component.html',
  styleUrl: './detect-reference.component.scss',
  imports: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  hostDirectives: [MatTooltip],
  host: {
    '[id]': 'id()',
    role: 'button',
    '[attr.tabindex]': 'available() ? 0 : -1',
    '[attr.disabled]': '!available() || null',
    '[attr.aria-disabled]': '!available() || null',
    'aria-haspopup': 'dialog',
    class: 'jrp-detect-reference',
    '[class.available]': 'available()',
    '[class.unavailable]': '!available()',
    '(click)': 'openPreview()',
    '(keydown.enter)': 'openPreview()',
  },
})
export class DetectReferenceComponent {
  private readonly dialog = inject(MatDialog);
  private readonly tooltip = inject(MatTooltip, { host: true });

  readonly knownReferenceId = input<string>('', { alias: 'dataIdentifier' });
  readonly unknownReferenceId = input<string>('', {
    alias: 'dataUnknownRefId',
  });
  readonly offset = input(0, {
    transform: numberAttribute,
    alias: 'dataOffset',
  });
  readonly abstract = input<string>(undefined, { alias: 'dataAbstract' });
  readonly startDate = input<string>(undefined, {
    alias: 'dataMetadataStartDate',
  });
  readonly status = input<string>('ACTIVE', { alias: 'dataMetadataStatus' });
  readonly shortTitle = input<string>(undefined, { alias: 'dataShortTitle' });
  readonly solution = input<string>(undefined, {
    alias: 'dataMetadataSolution',
  });

  protected readonly referenceId = computed(
    () => this.knownReferenceId() || this.unknownReferenceId(),
  );
  protected readonly available = computed(() => !!this.knownReferenceId());

  protected id = computed(
    () =>
      `jrp-detect-reference-${
        this.available()
          ? `${this.referenceId()}-${this.offset()}`
          : this.referenceId()
      }`,
  );

  readonly isLawArticleReference = computed(() => {
    const identifier = this.referenceId();
    return Boolean(identifier.startsWith('LEGIART'));
  });

  constructor() {
    effect(() => {
      this.tooltip.disabled = !this.available();
    });
    effect(() => {
      this.tooltip.message = `${this.isLawArticleReference() ? "Voir l'article de loi" : 'Voir la décision'} ${this.referenceId()}`;
    });
  }

  protected openPreview(): void {
    if (this.available()) {
      if (this.isLawArticleReference()) {
        this.openLawArticlePreview();
      } else {
        this.openCourtDecisionPreview();
      }
    }
  }

  private openLawArticlePreview(): void {
    this.dialog.open(DetectLawArticlePreviewDialogComponent, {
      data: {
        id: this.referenceId(),
        shortTitle: this.shortTitle(),
        abstract: this.abstract(),
        startDate: this.startDate(),
        status: this.status(),
      },
      width: '640px',
    });
  }

  private openCourtDecisionPreview(): void {
    this.dialog.open(DetectCourtDecisionPreviewDialogComponent, {
      data: {
        id: this.referenceId(),
        shortTitle: this.shortTitle(),
        abstract: this.abstract(),
        operative: this.solution(),
      },
      width: '640px',
    });
  }
}
