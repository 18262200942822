<h2 mat-dialog-title>{{ data.shortTitle }}</h2>
<mat-dialog-content>
  @switch (data.status) {
    @case ("ACTIVE") {
      <p class="status active">
        Version en vigueur depuis le
        {{ data.startDate | date: "longDate" }}
      </p>
    }
    @case ("DEPRECATED") {
      <p class="status deprecated">Plus en vigueur</p>
    }
    @default {
      <p class="status">Inconnu</p>
    }
  }

  <p class="abstract">{{ data.abstract }}</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Fermer</button>
  <a
    mat-flat-button
    mat-dialog-close
    cdkFocusInitial
    [routerLink]="['/codes-et-lois', data.id]"
    target="_blank"
    >Voir l'article</a
  >
</mat-dialog-actions>
