import { Provider } from '@angular/core';
import {
  INTERCOM_MESSENGER_CONFIG,
  IntercomMessengerClientService,
  IntercomMessengerConfig,
} from './intercom-messenger-client.service';
import { MessengerClientService } from './messenger-client';
import { NoopMessengerClientService } from './noop-messenger-client.service';

export function provideMessenger(
  config: IntercomMessengerConfig | null,
): Provider[] {
  if (config) {
    return [
      { provide: INTERCOM_MESSENGER_CONFIG, useValue: config },
      {
        provide: MessengerClientService,
        useClass: IntercomMessengerClientService,
      },
    ];
  }
  return [
    {
      provide: MessengerClientService,
      useClass: NoopMessengerClientService,
    },
  ];
}
