import { InjectionToken } from '@angular/core';
import * as intercom from '@intercom/messenger-js-sdk';

export type IntercomMessenger = typeof intercom;

export function intercomMessengerFactory(): IntercomMessenger {
  return intercom;
}

export const INTERCOM_MESSENGER = new InjectionToken<IntercomMessenger>(
  'Intercom Messenger API SDK',
  { providedIn: 'root', factory: intercomMessengerFactory },
);
